<template>
  <b-input-group :append="rowsShow.length + ' records out'">
    <b-form-input v-model="filter" :placeholder="'Search ' + rows.length + ' records'" />
    <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
  </b-input-group>
</template>

<script>

export default {
  name: 'SearchInterface',
  props: ['namespace', 'rows'],
  computed: {
    rowsShow: function () {
      let rows = []
      rows = this.rows
      if (this.filter.length > 1) {
        rows = rows.filter(x => {
          const term = x.name + ' ' + x.headline + ' ' + x.country + ' ' + x.area_name + ' ' + x.policy_name
          return term.toUpperCase().match(this.filter.toUpperCase())
        })
      }
      this.$emit('input', rows)
      return rows
    }
  },
  created: function () {
    const searchTerm = this.$store.state.searchTerm
    if (searchTerm[this.namespace]) {
      this.filter = searchTerm[this.namespace]
    }
  },
  data () {
    const data = {
      filter: ''
    }
    return data
  },
  methods: {
    filterReset: function () {
      this.filter = ''
    }
  },
  watch: {
    filter: {
      handler: function (val) {
        const searchTerm = this.$store.state.searchTerm
        searchTerm[this.namespace] = val
        this.$store.commit('setSearchTerm', searchTerm)
      },
      deep: true
    }
  }
}
</script>
