<template>
  <b-input-group>
    <b-button>sorting {{ rowsShow.length }} records by </b-button>
    <b-form-select v-model="selectedProperty" :options="optionsProperty"></b-form-select>
    <b-form-select v-model="selectedDirection" :options="optionsDirection"></b-form-select>
  </b-input-group>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'SortInterface',
  props: ['namespace', 'optionsProperty', 'rows', 'property', 'direction'],
  computed: {
    rowsShow: function () {
      let rows = []
      rows = this.rows
      rows = _.orderBy(rows, [this.selectedProperty], [this.selectedDirection])
      this.$emit('input', rows)
      return rows
    }
  },
  created: function () {
    const sortSelected = this.$store.state.sortSelected
    if (sortSelected[this.namespace]) {
      this.selectedProperty = sortSelected[this.namespace].selectedProperty
      this.selectedDirection = sortSelected[this.namespace].selectedDirection
    } else {
      sortSelected[this.namespace] = {
        selectedProperty: this.selectedProperty,
        selectedDirection: this.selectedDirection
      }
      this.$store.commit('setSortSelected', sortSelected)
    }
  },
  data () {
    const data = {
      selectedProperty: this.property ? this.property : 'name',
      selectedDirection: this.direction ? this.direction : 'asc',
      optionsDirection: [
        { value: 'asc', text: 'Ascending' },
        { value: 'desc', text: 'Descending' }
      ]
    }
    return data
  },
  methods: {
  },
  watch: {
    selectedDirection: {
      handler: function (val) {
        const selected = this.$store.state.sortSelected
        selected[this.namespace].selectedDirection = val
        this.$store.commit('setSortSelected', selected)
      },
      deep: true
    },
    selectedProperty: {
      handler: function (val) {
        const selected = this.$store.state.sortSelected
        selected[this.namespace].selectedProperty = val
        this.$store.commit('setSortSelected', selected)
      },
      deep: true
    }
  }
}
</script>
